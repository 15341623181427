import { useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { CarFrontFill, Clock, Award, Tools } from 'react-bootstrap-icons';

const cardStyle = {
  background: 'transparent',
  color: 'black',
  border: 'none',
};

const IconWithColor = ({ Icon, color }) => <Icon color={color} className="mb-1 me-2" />;

function Descriptions() {
  const [services] = useState([
    {
      Icon: CarFrontFill,
      color: '#DD761C',
      title: 'Usługa z dojazdem do Ciebie',
      description: 'Oferujemy usługę na terenie Zielonej Góry, Nowej Soli, Żagania, Żar, Szprotawy, Głogowa, Bolesławca, Polkowic oraz pobliskich miejscowości.',
    },
    {
      Icon: Clock,
      color: '#DD761C',
      title: 'Krótki czas oczekiwania',
      description: 'Nasz krótki czas oczekiwania sprawia, że możesz cieszyć się odświeżoną tapicerką szybko i bez zbędnych opóźnień.',
    },
    {
      Icon: Award,
      color: '#DD761C',
      title: 'Profesionalne środki czystości',
      description: 'W procesie prania tapicerek stosujemy najlepsze, sprawdzone i bezpieczne preparaty uznanych producentów.',
    },
    {
      Icon: Tools,
      color: '#DD761C',
      title: 'Sprzet dopasowany do twoich potrzeb',
      description: 'Posiadamy różnorodny zestaw profesjonalnych maszyn i narzędzi, co pozwala nam zagwarantować naszym klientom skuteczność i wysoką jakość naszych usług.',
    },
  ]);

  return (
    <Container className='my-5'>
      <Row className="g-4">
        {services.map((service, index) => (
          <Col key={index} xs={12} md={6} lg={6}>
            <Card style={cardStyle}>
              <Card.Body>
                <Card.Title>
                  <IconWithColor Icon={service.Icon} color={service.color} />
                  {service.title}
                </Card.Title>
                <Card.Text>{service.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default Descriptions;
