import React, { useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import auto from './photos/autocollage.jpg'; 
import fotel from './photos/fotel.jpg';
import materac from './photos/materac.jpg';
import { IoMdPhotos } from "react-icons/io";

function GalleryM() {
    const [services] = useState([
        {
            src: fotel,
            title: 'Tapicerka Meblowa',
            description: 'Pranie tapicerki meblowej odświeża wnętrza, przywracając meblom pierwotną czystość i urok.'
        },
        {
            src: auto,
            title: 'Tapicerka Samochodowa',
            description: 'Pranie tapicerki samochodowej zapewnia szybkie i skuteczne usunięcie zabrudzeń, przywracając świeży wygląd wnętrza pojazdu.'
        },
        {
            src: materac,
            title: 'Materace i Dywany',
            description: 'Pranie materacy i dywanów odświeża wnętrza, eliminując niechciane zabrudzenia i zapachy.'
        }
    ]);

    return (
        <Container className='my-5'>
            <span style={{ color: '#FEB941' }}>W CZYM SIĘ SPECJALIZUJEMY ?</span>
            <h2 style={{ color: '#6DC5D1', fontWeight:'bold' }}>Nasze Usługi</h2>
            <Button href="/photos" variant="outline-info">Odkryj więcej zdjęć przed i po<IoMdPhotos className='mb-1 ms-2'/></Button>
            <p className="mt-3 mb-5">Specjalizujemy się w czyszczeniu tapicerki samochodowej oraz meblowej, takiej jak fotele, kanapy, materace i krzesła.</p>
            <Row>
                {services.map((service, index) => (
                    <Col key={index} className='d-flex justify-content-center'>
                        <Card className='mb-4' style={{ width: '25rem', borderColor:'#6DC5D1'}}>
                            <Card.Img src={service.src} />
                            <Card.Body>
                                <Card.Title>{service.title}</Card.Title>
                                <Card.Text>{service.description}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
}

export default GalleryM;