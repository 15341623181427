import { Container, Row, Col } from 'react-bootstrap';
import { House, Envelope, Telephone, Facebook, Tiktok } from 'react-bootstrap-icons';
import Image from 'react-bootstrap/Image';
import Logo2 from "./photos/logo2.png";


function Footer(){
    return(

        <div className='text-center text-lg-start text-muted' style={{backgroundColor: 'transparent', position:'relative'}}>
            <section style={{borderTop:'1px solid rgb(0,0,0,0.3)'}}>
                <Container className='text-center text-md-start mt-5'>
                    <Row className='mt-3'>
                        <Col md='3' lg='4' xl='3' className='mx-auto mb-4'>
                            <h6 className='text-uppercase fw-bold mb-4'><Image src={Logo2} height={'45rem'}/>cleanwashzg.pl</h6>
                            <p>Profesjonalne pranie tapicerki w Twojej okolicy, wraz z dojazdem do Ciebie.</p>
                        </Col>
                        <Col md='2' lg='2' xl='2' className='mx-auto mb-4'>
                            <h6 className='text-uppercase fw-bold mb-4'>Menu</h6>
                            <p><a href='/photos' className='text-reset' >Galeria</a></p>
                            <p>
                                <a className='text-reset' onClick={() => {
                                    const element = document.getElementById('contact');
                                    element?.scrollIntoView({behavior: 'smooth'})
                                }}>Kontakt</a>
                            </p>
                            <p>
                                <a className='text-reset' onClick={() => {
                                    const element = document.getElementById('reviews');
                                    element?.scrollIntoView({behavior: 'smooth'})
                                }}>Opinie</a>
                            </p>
                        </Col>
                        <Col md='3' lg='2' xl='2' className='mx-auto mb-4'>
                            <h6 className='text-uppercase fw-bold mb-4'>Social Media</h6>
                            <p><a href='https://www.facebook.com/profile.php?id=100067156426175&locale=pl_PL' className='text-reset' ><Facebook className='me-3'/>Facebook</a></p>
                            <p><a href='https://www.tiktok.com/@cleanwashzg' className='text-reset'><Tiktok className='me-3'/>Tiktok</a></p>
                        </Col>
                        <Col md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
                            <h6 className='text-uppercase fw-bold mb-4'>Kontakt</h6>
                            <p><House color='secondary' className='me-3' />Żagań, 68-100, Lubuskie</p>
                            <p><Envelope color='secondary' className='me-3' />cleanwash@wp.pl</p>
                            <p><Telephone color='secondary' className='me-3' /> + 48 786 661 612</p>
                        </Col>
                    </Row>
                </Container>
            </section>
            <div className='text-center p-4' style={{ backgroundColor: '#bfd5d9' }}>cleanwashzg.pl 2024</div>
        </div>

    )
}

export default Footer;