import 'bootstrap/dist/css/bootstrap.css';
import { Container, Nav, Navbar, Image } from 'react-bootstrap';
import { Telephone, EnvelopeAt } from 'react-bootstrap-icons';
import Logo2 from './photos/logo2.png';

function Navi() {

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: id === 'contact' ? 'center' : 'start' });
    } else {
      window.location.href = '/';
    }
  };

  return (
    <>
      <Navbar expand="sm" style={{ backgroundColor: '#6DC5D1' }} className='p-0'>
        <Container className='d-flex justify-content-center'>
          <span style={{ color: 'white' }} className='m-1 me-3'>
            <Telephone className='me-1 mb-1' />786 661 612
          </span>
          <span style={{ color: 'white' }} className='m-1 ms-3'>
            <EnvelopeAt className='me-1 mb-1' />cleanwash@wp.pl
          </span>
        </Container>
      </Navbar>

      <Navbar collapseOnSelect expand="md" className="justify-content-center" data-bs-theme="light">
        <Container>
          <Navbar.Brand href="/">
            <Image src={Logo2} height='45rem' className="m-0" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="/photos" className="ms-3 ms-lg-5 d-flex justify-content-center">Galeria</Nav.Link>
              <Nav.Link onClick={() => scrollToSection('contact')} className="ms-3 ms-lg-5 d-flex justify-content-center">Kontakt</Nav.Link>
              <Nav.Link onClick={() => scrollToSection('reviews')} className="ms-3 ms-lg-5 d-flex justify-content-center">Opinie</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Navi;